import logo from './assets/logo.png';
import styles from './App.module.scss';

function App() {
  return (
    <div className={styles.container}>
        <img src={logo} alt={"logo"} className={styles.logo}/>
        <span>Próximamente</span>
    </div>
  );
}

export default App;
